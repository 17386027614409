type ActionType = 'OPEN_URL' | 'OPEN_APP_URL' | 'OPEN_RATE_US_DIALOG' | 'SET_AND_SAVE_NEW_APP_URL'

type Action = {
  type: ActionType
  payload?: any
  error?: boolean
}

function createAction(type: ActionType, payload?: any, error: boolean = false): Action {
  return {
    type,
    payload,
    error
  }
}

function dispatchToNativeWrapper(action: Action): void {
  if (!isOnNative()) return

  /*
  Would just rebind like window.ReactNativeWebView.postMessage || window.postMessage
  but that changes the functions "this" which causes Android webview to get real mad
  */
  // @ts-ignore
  if (window.ReactNativeWebView?.postMessage) {
    // @ts-ignore
    window.ReactNativeWebView.postMessage(JSON.stringify(action))
    // @ts-ignore
  } else if (window.NativeWebView?.postMessage) {
    // @ts-ignore
    window.NativeWebView.postMessage(JSON.stringify(action))
  } else {
    // normal window.postMessage expects two arguments
    // as opposed to window.ReactNativeWebView.postMessage
    window.postMessage(JSON.stringify(action), '*')
  }
}

export function getNativeAppVersion() {
  return window.Tomra?.nativeAppVersion
}

export function getNativeDeviceInfo() {
  return window.Tomra?.nativeDeviceInfo
}

export function getNativeNotificationToken() {
  return window.Tomra?.notificationToken || ''
}

export function isOnNative() {
  return !!getNativeAppVersion()
}

export function isOnPwa() {
  if (window.matchMedia('(display-mode: standalone)').matches) {
    return true
  } else {
    return false
  }
}

export function satisfiesNativeAppVersion({ ios, android }: { ios: string; android: string }) {
  const OS = getNativeDeviceInfo()?.OS
  const actualVersion = getNativeAppVersion()

  if (OS && actualVersion) {
    const actualVersionParts = actualVersion.split('.')
    const checkVersionParts = (OS === 'ANDROID' ? android : ios).split('.')
    const actualMajorVersion = parseInt(actualVersionParts[0], 10)
    const checkMajorVersion = parseInt(checkVersionParts[0], 10)
    const actualMinorVersion = parseInt(actualVersionParts[1], 10)
    const checkMinorVersion = parseInt(checkVersionParts[1], 10)

    return (
      actualMajorVersion > checkMajorVersion ||
      (actualMajorVersion === checkMajorVersion && actualMinorVersion >= checkMinorVersion)
    )
  }
}

export function usesiOSNativeWrapper() {
  // @ts-ignore
  return window.Tomra?.webView && window.Tomra?.webView === 'iOSNativeWrapper'
}

export function openEmbeddedBrowserInNativeWrapper(url: string) {
  dispatchToNativeWrapper(createAction('OPEN_URL', url))
}

export function openAppUrl(url: string) {
  dispatchToNativeWrapper(createAction('OPEN_APP_URL', url))
}

export function openRateUsDialog() {
  dispatchToNativeWrapper(createAction('OPEN_RATE_US_DIALOG'))
}

export function setAndSaveAppUrl(url: string) {
  if (isOnNative()) {
    dispatchToNativeWrapper(createAction('SET_AND_SAVE_NEW_APP_URL', url))
  } else {
    window.location.href = url
  }
}
