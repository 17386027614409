import { arrayUnion, arrayRemove } from 'firebase/firestore'
import { logError } from '@tomra/datadog-browser-logging'
import { updateUserDoc } from './user-settings-service'
import { usesiOSNativeWrapper, getNativeNotificationToken } from './react-native-service'
import { WEB_PUSH_PUBLIC_KEY } from '../lib'

let webPushSubscription

const urlBase64ToUint8Array = base64String => {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/')

  const rawData = window.atob(base64)
  const outputArray = new Uint8Array(rawData.length)

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i)
  }
  return outputArray
}

const getSanitisedIOSNotificationToken = () => {
  let notificationToken = getNativeNotificationToken()

  try {
    // The order of object properties from iOS can vary, causing the stringified json encoded token value to vary
    // We want to make sure we always insert the same encoded value in Firestore
    const { apnsToken, bundleId, sound } = JSON.parse(atob(notificationToken))
    notificationToken = btoa(JSON.stringify({ apnsToken, bundleId, sound }))
  } catch (error) {
    // don't care
  }

  return notificationToken
}

export function hasNotificationSupport() {
  return !!(window.Notification || (usesiOSNativeWrapper() && getNativeNotificationToken()))
}

export const initAPNsPush = async (locationId: string) => {
  try {
    const notificationToken = getSanitisedIOSNotificationToken()

    if (notificationToken) {
      await updateUserDoc(locationId, { notificationDevices: arrayUnion(notificationToken) })
    }
  } catch (error: any) {
    logError(new Error('Failed to register notification device'), error)
  }
}

export const initWebPush = async (locationId: string, isRetryAttempt: boolean = false) => {
  try {
    const registration = await navigator.serviceWorker.register('/web-push-sw.js')

    await navigator.serviceWorker.ready

    await Notification.requestPermission().then(permission => {
      if (permission === 'granted') return true
      else throw new Error('Notification permission denied')
    })

    webPushSubscription = await registration.pushManager.getSubscription()

    if (!webPushSubscription) {
      webPushSubscription = await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array(WEB_PUSH_PUBLIC_KEY)
      })
    }

    if (webPushSubscription) {
      await updateUserDoc(locationId, { notificationDevices: arrayUnion(btoa(JSON.stringify(webPushSubscription))) })
    }
  } catch (error: any) {
    if (isRetryAttempt) logError(new Error('Failed to set up web push'), error)
    else setTimeout(() => initWebPush(locationId, true), 2000)
  }
}

export const unregisterNotifications = async (locationId: string) => {
  try {
    const tokenToRemove = usesiOSNativeWrapper()
      ? getSanitisedIOSNotificationToken()
      : btoa(JSON.stringify(webPushSubscription))

    await updateUserDoc(locationId, { notificationDevices: arrayRemove(tokenToRemove) })
  } catch (error: any) {
    logError(new Error('Failed to unregister notification device'), error)
  }
}
