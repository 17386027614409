import { v4 as uuidv4 } from 'uuid'
import { logError } from '@tomra/datadog-browser-logging'
import { KEYCLOAK_HOST } from './environment'
import { unregisterNotifications, usesiOSNativeWrapper, openEmbeddedBrowserInNativeWrapper } from '../services'
import {
  getItemFromWebStorage,
  setItemInWebStorage,
  removeItemFromWebStorage,
  getQueryParameterByName,
  FIREBASE
} from '../lib'
import { ampli } from '../ampli'

function openBrowserWindow(url: string) {
  if (usesiOSNativeWrapper()) {
    openEmbeddedBrowserInNativeWrapper(url)
  } else {
    window.location.href = url
  }
}

function getKcLocale() {
  return localStorage.getItem('reactI18nLocale')
}

class HttpError extends Error {
  status: number
  statusText: string
  body: string | Record<string, unknown>

  constructor(statusCode: number, statusText: string, body: string | Record<string, unknown>) {
    super(`Unsuccessful HTTP response: ${statusCode} ${statusText}`)
    this.name = 'HttpError'
    this.status = statusCode
    this.statusText = statusText
    this.body = body
  }
}

export class AuthStore {
  _accessToken: string = ''
  _refreshToken: string = ''
  _realm: string
  _clientId: string
  _keycloakHost: string
  _unsubscribeFirebaseAuth?: () => void

  constructor(realm: string, clientId: string, keycloakHost: string) {
    this._realm = realm
    this._clientId = clientId
    this._keycloakHost = keycloakHost
  }

  _performCodeRequest = code => {
    return fetch(`${this._keycloakHost}/realms/${this._realm}/protocol/openid-connect/token`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: `code=${code}&client_id=${this._clientId}&redirect_uri=${window.location.origin}&grant_type=authorization_code`
    }).then(response => {
      if (response.ok) return response.json()
      else throw new HttpError(response.status, response.statusText, 'Failed to exchange auth code for tokens')
    })
  }

  _performTokenRequest = (refreshToken = this._refreshToken) => {
    return fetch(`${this._keycloakHost}/realms/${this._realm}/protocol/openid-connect/token`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: `refresh_token=${refreshToken}&client_id=${this._clientId}&grant_type=refresh_token`
    }).then(response => {
      if (response.ok) return response.json()
      else throw new HttpError(response.status, response.statusText, 'Failed to refresh token')
    })
  }

  initWithCode = async (code: string) => {
    try {
      const { access_token, refresh_token } = await this._performCodeRequest(code)

      this._accessToken = access_token
      this._refreshToken = refresh_token

      setItemInWebStorage('refreshToken', refresh_token)
      window.history.replaceState({}, document.title, '/')
    } catch (error: any) {
      logError(new Error('Failed to initialise auth with code'), error)
      // Throw so that init() properly rejects
      throw error
    }
  }

  initWithRefreshToken = async (refreshToken: string = this._refreshToken) => {
    try {
      const { access_token } = await this._performTokenRequest(refreshToken)

      this._accessToken = access_token
      this._refreshToken = refreshToken
    } catch (error: any) {
      if (error.status === 400) {
        return Promise.reject('Unauthenticated')
      } else {
        logError(new Error('Failed to initialise auth with refresh token'), error)
        throw error
      }
    }
  }

  init = async () => {
    // Already authenticated
    if (this._accessToken && this._refreshToken) {
      return Promise.resolve()
    }

    const authCode = getQueryParameterByName('code')
    const refreshToken = await getItemFromWebStorage('refreshToken')

    if (authCode) {
      return this.initWithCode(authCode)
    } else if (refreshToken) {
      return this.initWithRefreshToken(refreshToken)
    } else {
      window.history.replaceState({}, '', '/')
      return Promise.reject('Unauthenticated')
    }
  }

  login = () => {
    ampli.loginInitiated()
    const state = uuidv4()
    const nonce = uuidv4()
    const redirectUrl = encodeURIComponent(window.location.origin)
    const locale = getKcLocale()
    const url = `${this._keycloakHost}/realms/${this._realm}/protocol/openid-connect/auth?client_id=${this._clientId}&redirect_uri=${redirectUrl}&state=${state}&nonce=${nonce}&response_type=code&scope=openid&ui_locales=${locale}`

    openBrowserWindow(url)
  }

  logout = async () => {
    const redirectUrl = encodeURIComponent(window.location.origin)
    const locale = getKcLocale()
    const url = `${this._keycloakHost}/realms/${this._realm}/protocol/openid-connect/logout?redirect_uri=${redirectUrl}&ui_locales=${locale}`

    this._accessToken = ''
    this._refreshToken = ''
    await removeItemFromWebStorage('refreshToken')
    await unregisterNotifications(window.Tomra.currentLocationId || '')
    await FIREBASE.auth.signOut()
    openBrowserWindow(url)
  }

  goToAccountManagement = (): void => {
    const locale = getKcLocale()
    openBrowserWindow(`${this._keycloakHost}/realms/${this._realm}/account?ui_locales=${locale}`)
  }

  getToken = () => {
    return this._accessToken
  }

  getRefreshToken = () => {
    return this._refreshToken
  }

  fetchNewToken = async () => {
    try {
      const { access_token } = await this._performTokenRequest()
      this._accessToken = access_token
    } catch (error) {
      if (this._accessToken) this.logout()
      throw error
    }
  }
}

export const authentication = new AuthStore('TomraConnectUsers', 'dashboard', KEYCLOAK_HOST)
